:root {
  /* colors */
  --color-dark: #1b212e;
  --color-light: #FFFFFF;

  --color-primary-light: #03a9f4;
  --color-primary: #03a9f4;
  --color-primary-dark: #1871b5;

  --color-warning-light: #F4B03A;
  --color-warning: #F2A51E;
  --color-warning-dark: #D68D0C;

  --color-secondary-light: #5C819E;
  --color-secondary: #2C3E4C;
  --color-secondary-dark: #25343F;

  --color-success-light: #04F45E;
  --color-success: #03C04A;
  --color-success-dark: #02A03E;

  --color-danger-light: #E8494E;
  --color-danger: #E3242B;
  --color-danger-dark: #C2191F;

  --color-info-light: #2196f3;
  --color-info: #2196f3;
  --color-info-dark: #1871B5;

  --color-neutral-light: #FCFCFC;
  --color-neutral: #F5F5F5;
  --color-neutral-dark: #C0C3CC;
}
